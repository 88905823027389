import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
// import Contact from "";
import ProtectedRoutes from "utils/ProtectedRoutes";
import FallbackLoading from "components/FallbackLoading";

const Contact = React.lazy(() => import("./../../pages/Contact"));

export const contactRoutes: RouteObject[] = [
	{
		path: routePaths.CONTACT.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback={<FallbackLoading />}>
					<Contact />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
