import { createSlice } from "@reduxjs/toolkit";
import { IConferenceAction, IDialAction, IHangupAction, IHoldAction, IKeyPressAction, IMergeAction, IModifyAditionalStatusAction, IModifyMainStatusAction, IPauseAction, ISendMessageAction, ISidecarActionProgress, ISidecarState, ITransferAction } from "./sidecarTypes";
import { v4 as uuidv4 } from 'uuid';
import { WritableDraft } from "immer/dist/internal";
import sip from "lib/sip";
import { store } from "redux/store";
import { Slide, toast } from "react-toastify";
import { manipulateXML, newXMLConfigTemplate, saveSidecarConfigToLocalStorage } from "utils/sidecar/sidecarXML";

const initialSidecarState: ISidecarState = {
	sidecarConfig:[],
	sidecarConfigNewTemp:{
		uuid:"",
		actionList:[],
		extension:"",
		name:"",
	},
	showSidecarSidebar: false,
	showButtonProgramming: false,
	showChooseActions: false,
	tabSelected: "extension",
	showPauseDialogue: {uuid:"", show:false},
	showDialDialogue: {uuid:"", show:false},
	showConferenceDialogue: {uuid:"", show:false},
	showMainStatusDialogue: {uuid:"", show:false},
	showAdditionalStatusDialogue: {uuid:"", show:false},
	showSendMessageDialogue: {uuid:"", show:false},
	showKeypressDialogue: {uuid:"", show:false},
	showTransferDialogue: {uuid:"", show:false},
	extensionPresenceChanged: [],
	extensionStatusChanged: [],
	showStatusMenu:undefined,
	lastSync:undefined,
	fullScreen:true,
	sidecarView:false,
};
const executeNext=(state: WritableDraft<ISidecarState>, payload: any)=>{
	const uuid = payload.uuid;
	const actionUUID = payload.actionUUID;
	const result = payload.result;
	// alert(actionUUID)
	for (let index = 0; index < state.sidecarConfig.length; index++) {
		if (state.sidecarConfig[index].uuid === uuid) {
			// alert(`Execution status: ${state.sidecarConfig[index].progress?.status}`)
			if(state.sidecarConfig[index].progress?.status !== "Active"){
				// alert(`Execution returning`)
				return
			}
			for(let actionIndex = 0; actionIndex < state.sidecarConfig[index].actionList.length; actionIndex++){
				console.log(state.sidecarConfig[index].actionList[actionIndex].uuid)
				console.log(actionUUID)
				console.log("===")
				if(state.sidecarConfig[index].actionList[actionIndex].uuid === actionUUID){

					// alert(`Executing ${state.sidecarConfig[index].actionList[actionIndex].uuid}`)
					// alert(`Status: "${state.sidecarConfig[index].actionList[actionIndex].status}"`)
					const start =(action: WritableDraft<IDialAction> | WritableDraft<ISendMessageAction> | WritableDraft<IPauseAction> | WritableDraft<ITransferAction> | WritableDraft<IConferenceAction> | WritableDraft<IMergeAction> | WritableDraft<IKeyPressAction> | WritableDraft<IHangupAction> | WritableDraft<IHoldAction> | WritableDraft<IModifyMainStatusAction> | WritableDraft<IModifyAditionalStatusAction>)=>{
						if(state.sidecarConfig[index].progress?.pause === true){
							// alert(`Execution paused: returning`)
							return
						}
						action.status = "Inprogress"
						// alert(`Starting ${action.name} =  ${action.uuid}`)
						// alert(action.uuid)
						const sidecar: { uuid: string; actionUUID: string; result?:string} = {
							uuid:uuid,
							actionUUID:action.uuid,
						}
						const progress = state.sidecarConfig[index].progress
						if(progress)
							progress.currentStepInprogress = action.uuid
						state.sidecarConfig[index].currentExecutionUUID = action.uuid
						switch(action.name){
							case "Dial":
								{const number = action.number;
								setTimeout(()=>{sip.call(number, sidecar);},2000);}
								break;
							case "Pause":
								{const seconds = action.seconds
								sidecar.result = "Done"
								setTimeout(()=>{
    								store.dispatch({type:"sidecar/executeNextSidecarAction", payload:sidecar})
								},Number(seconds)*1000)}
								break;
							case "Hangup":
								{setTimeout(()=>{sip.hungupSidecar(sidecar);},2000);}
								break;
							case "Hold":
								{setTimeout(()=>{sip.holdSidecar(sidecar);},2000);}
								break;
							case "KeyPress":
								{const value = action.number;
								setTimeout(()=>{sip.sendDTMFSidecar(sidecar,value);},2000);}
								break;
							case "Merge":
								{setTimeout(()=>{sip.mergeSidecar(sidecar);},2000);}
								break;
							case "Transfer":
								{const value = action.number;
								const att = action.transferType=="0"?false:true;
								setTimeout(()=>{sip.transferCallSidecar(sidecar,value,att);},2000);}
								break;
							case "Conference":
								{const number = action.numbers[0];
								setTimeout(()=>{sip.call(number, sidecar);},2000);}
								break;
							case "ModifyAditionalStatus":
								{const toStatus = [...action.toStatus];
								const fromStatuses = [...action.fromStatuses];
								setTimeout(()=>{
									store.dispatch({type:"sidecar/updateStatus", payload:{action:"additional_status", toStatus:toStatus, fromStatuses:fromStatuses, sidecar:sidecar }})
								},2000);}
								break;
							case "ModifyMainStatus":
								{const toStatus = [...action.toStatus];
								const fromStatuses = [...action.fromStatuses];
								setTimeout(()=>{
									store.dispatch({type:"sidecar/updateStatus", payload:{action:"main_status", toStatus:toStatus, fromStatuses:fromStatuses, sidecar:sidecar }})
								},2000);}
								break;
							case "SendMessage":
								{const fromNumber = action.fromNumber;
								const toNumber = action.toNumber;
								const message = action.message;
								setTimeout(()=>{
									store.dispatch({type:"sidecar/sendMessage", payload:{from:fromNumber, to:toNumber, message:message, sidecar:sidecar}})
								},2000)}
								break;
						}
					}
					switch(state.sidecarConfig[index].actionList[actionIndex].status){
						case "Pending":
							//process Current
							// alert("New process because pending")
							start(state.sidecarConfig[index].actionList[actionIndex])
						break;
						case "Inprogress":
							//If we dont have result keep waiting
							if(!result){
								// alert("Execution Waiting")
								break;
							}
							//If We have result 
							//Mark current status to Done or Fail
							//process Next or Finish
							// alert(`Execution is: ${result}`)
							if(!(state.sidecarConfig[index].actionList[actionIndex].status === "Done"||state.sidecarConfig[index].actionList[actionIndex].status === "Fail")) //Once done, done
								state.sidecarConfig[index].actionList[actionIndex].status = result // Fail | Done
								const currentAction = state.sidecarConfig[index].actionList[actionIndex]
								if(currentAction.name === "Conference" && currentAction.status==="Done"){
									{const numbers = [...currentAction?.numbers];
									const uuid = currentAction.uuid;
									setTimeout(()=>{sip.conferenceSidecar(uuid, numbers)},500);}
								}
							// alert("updateing to " + result )
						case "Done":
						case "Fail":
							//process Next
							if(actionIndex+1 < state.sidecarConfig[index].actionList.length){
								// alert("Processing next")
								if(state.sidecarConfig[index].actionList[actionIndex+1].status === "Pending" ){
									start(state.sidecarConfig[index].actionList[actionIndex+1])
								}
							}else{
								toast(`Sidecar '${state.sidecarConfig[index].name}' complited`, {
									position: "top-right",
									autoClose: 3000,
									hideProgressBar: true,
									closeOnClick: true,
									pauseOnHover: true,
									draggable: true,
									progress: undefined,
									transition: Slide,
									// theme: theme ? "dark" : "light", //@@fix
								  });
								if(state.sidecarConfig[index].progress)
									delete state.sidecarConfig[index].progress;
								if(state.sidecarConfig[index].currentExecutionUUID)
									delete state.sidecarConfig[index].currentExecutionUUID;
								for(let actionIndex = 0; actionIndex < state.sidecarConfig[index]?.actionList.length ; actionIndex++){
									if(state.sidecarConfig[index].actionList[actionIndex].status)
										delete state.sidecarConfig[index].actionList[actionIndex].status
								}
							}
						break;
						default:
							alert("unknown status")
					}
				}
			}
			return;
		}
	}			
}
const sidecarSlice = createSlice({
	name: "sidecar",
	initialState: initialSidecarState,

	reducers: {
		setSidecarConfigNewTemp(state, action) {
			state.sidecarConfigNewTemp = action.payload;
		},
		setSidecarView(state, action) {
			state.sidecarView = action.payload;
		},
		setFullScreen(state, action) {
			state.fullScreen = action.payload;
		},
		addExtensionPresenceChanged(state, action) {
			state.extensionPresenceChanged = [action.payload, ...state.extensionPresenceChanged];
			console.log(JSON.parse(JSON.stringify(state.extensionPresenceChanged)))
		},
		updateShowStatusMenu(state, action) {
			state.showStatusMenu = action.payload;
		},
		setLastSync(state, action) {
			state.lastSync = action.payload;
		},
		addExtensionStatusChanged(state, action) {
			state.extensionStatusChanged = [action.payload, ...state.extensionStatusChanged];
			console.log(JSON.parse(JSON.stringify(state.extensionStatusChanged)))
		},
		setSidecarConfigNewTempUUID(state, action) {
			state.sidecarConfigNewTemp.uuid = action.payload;
		},
		setSidecarConfigNewTempName(state, action) {
			state.sidecarConfigNewTemp.name = action.payload;
		},
		setSidecarConfigNewTempExtension(state, action) {
			state.sidecarConfigNewTemp.extension = action.payload;
		},
		addSidecarConfigNewTempActionList(state, action) {
			if(action.payload.uuid){
				const uuid = action.payload.uuid;
				for (let index = 0; index < state.sidecarConfigNewTemp.actionList.length; index++) {
					if (state.sidecarConfigNewTemp.actionList[index].uuid === uuid) {
						state.sidecarConfigNewTemp.actionList[index] = action.payload
						break;
					}
				}
			}else{
				action.payload.uuid = uuidv4()
				state.sidecarConfigNewTemp.actionList = [...state.sidecarConfigNewTemp.actionList, action.payload];
			}
		},
		removeSidecarConfigNewTempActionList(state, action) {
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfigNewTemp.actionList.length; index++) {
				if (state.sidecarConfigNewTemp.actionList[index].uuid === uuid) {
					state.sidecarConfigNewTemp.actionList = [
						...state.sidecarConfigNewTemp.actionList.slice(0, index),
						...state.sidecarConfigNewTemp.actionList.slice(index + 1),
					];
					break;
				}
			}
		},
		resetSidecarConfigNew(state) {
			state.sidecarConfigNewTemp = {
				uuid:"",
				actionList:[],
				extension:"",
				name:"",
			};
		},
		addSidecarActionFromFile(state, action) {
			for(let index = 0; index < action.payload.actionList?.length; index++){
				if(!action.payload.actionList[index]?.uuid){
					action.payload.actionList[index].uuid = uuidv4();
				}
			}
			// Append At The End
			action.payload.uuid = uuidv4()
			const xmlString = localStorage.getItem("sidecarConfig")?localStorage.getItem("sidecarConfig"):newXMLConfigTemplate();
			const updatedXML = manipulateXML({xmlString:xmlString?xmlString:"", action:{type:"add", payload:{sidecarConfig:action.payload}}})
			const tagName = updatedXML?.newButtonID
			if(updatedXML.xmlString && updatedXML.xmlString !== ""){
				saveSidecarConfigToLocalStorage(updatedXML.xmlString)
				state.uploadToServer = true;//Upload to server
			}
			action.payload.tagName = tagName;
			state.sidecarConfig = [...state.sidecarConfig, action.payload];
		},
		addSidecarAction(state, action) {
			for(let index = 0; index < action.payload.actionList?.length; index++){
				if(!action.payload.actionList[index]?.uuid){
					action.payload.actionList[index].uuid = uuidv4();
				}
			}
			if(action.payload.uuid){//Update
				const uuid = action.payload.uuid
				for (let index = 0; index < state.sidecarConfig.length; index++) {
					if (state.sidecarConfig[index].uuid === uuid) {
						state.sidecarConfig[index] = state.sidecarConfigNewTemp
						const sidecarConfigLocalStorage = localStorage.getItem("sidecarConfig");
						const xmlString = sidecarConfigLocalStorage && sidecarConfigLocalStorage?.length > 70 ? sidecarConfigLocalStorage:newXMLConfigTemplate();
						const updatedXML = manipulateXML({xmlString:xmlString?xmlString:"", action:{type:"update", payload:{sidecarConfig:action.payload }}})
						const tagName = updatedXML?.buttonID
						if(updatedXML.xmlString && updatedXML.xmlString !== ""){
							saveSidecarConfigToLocalStorage(updatedXML.xmlString)
							state.uploadToServer = true;//Upload to server
						}
						if(tagName){
							action.payload.tagName = tagName;
						}
					}
				}
			}else{//New
				action.payload.uuid = uuidv4()
				if(!action.payload?.tagName){
					const sidecarConfigLocalStorage = localStorage.getItem("sidecarConfig");
					const xmlString = sidecarConfigLocalStorage && sidecarConfigLocalStorage?.length > 70 ? sidecarConfigLocalStorage:newXMLConfigTemplate();
					const updatedXML = manipulateXML({xmlString:xmlString?xmlString:"", action:{type:"add", payload:{sidecarConfig:action.payload}}})
					const tagName = updatedXML?.newButtonID
					if(updatedXML.xmlString && updatedXML.xmlString !== ""){
						saveSidecarConfigToLocalStorage(updatedXML.xmlString)
						state.uploadToServer = true;//Upload to server
					}
					action.payload.tagName = tagName;
				}
				state.sidecarConfig = [...state.sidecarConfig, action.payload];
			}
		},
		removeSidecarAction(state, action) {
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					const tagName = state.sidecarConfig[index].tagName
					state.sidecarConfig = [
						...state.sidecarConfig.slice(0, index),
						...state.sidecarConfig.slice(index + 1),
					];
					if(tagName){
						const xmlString = localStorage.getItem("sidecarConfig");
						const updatedXML = manipulateXML({xmlString:xmlString?xmlString:"", action:{type:"delete", payload:{tagName:tagName}}})
						if(updatedXML.xmlString && updatedXML.xmlString !== ""){
							saveSidecarConfigToLocalStorage(updatedXML.xmlString)
							state.uploadToServer = true;//Upload to server
						}
					}
					break;
				}
			}
		},
		moveSidecarActionList(state, action) {
			const from = action.payload.from;
			const to = action.payload.to;
			const sidecarConfigNewTemp = state.sidecarConfigNewTemp?.actionList
			if(sidecarConfigNewTemp.length){
				const element = sidecarConfigNewTemp[from];
				sidecarConfigNewTemp.splice(from, 1);
				sidecarConfigNewTemp.splice(to, 0, element);
			}
		},
		editSidecarAction(state, action) {
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					state.sidecarConfigNewTemp = state.sidecarConfig[index]
					state.showButtonProgramming = true
				}
			}
		},
		executeNextSidecarAction(state, action) {
			executeNext(state, action.payload)
		},
		executePlaySidecarAction(state, action) {
			console.log(action)
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if(state.sidecarConfig[index].progress?.status === "Active" && state.sidecarConfig[index].uuid !== uuid){
					alert(`Sidecar ${state.sidecarConfig[index].name} operation is in progress, do you want to stop it and start new one?`)
					return;
				}
			}
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					const currentStepInprogress = state.sidecarConfig[index].progress?.currentStepInprogress ? state.sidecarConfig[index].progress?.currentStepInprogress:state.sidecarConfig[index].actionList[0]?.uuid;
					const progress:ISidecarActionProgress = {
						status: "Active",
						pause: false,
						currentStepInprogress: currentStepInprogress? currentStepInprogress: ""
					}
					state.sidecarConfig[index].progress = progress
					if(!state.sidecarConfig[index].currentExecutionUUID){
						state.sidecarConfig[index].currentExecutionUUID = uuidv4()
						//New
						// alert("new from play")
						for(let actionIndex = 0; actionIndex < state.sidecarConfig[index]?.actionList.length ; actionIndex++){
							state.sidecarConfig[index].actionList[actionIndex].status = "Pending";
						}
					}
					// alert("resume"+state.sidecarConfig[index].progress?.currentStepInprogress)
					const payload = {
							uuid:uuid,
							actionUUID:state.sidecarConfig[index].progress?.currentStepInprogress
						}
					executeNext(state, payload)
					// alert(state.sidecarConfig[index].progress?.currentStepInprogress)
					// alert(state.sidecarConfig[index].currentExecutionUUID)
				}
			}
		},
		executePauseSidecarAction(state, action) {
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					const progress = state.sidecarConfig[index].progress
					if(progress)
						progress.pause = true
				}
			}
		},
		executeStopSidecarAction(state, action) {
			const uuid = action.payload;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					if(state.sidecarConfig[index].progress)
						delete state.sidecarConfig[index].progress;
					if(state.sidecarConfig[index].currentExecutionUUID)
						delete state.sidecarConfig[index].currentExecutionUUID;
					for(let actionIndex = 0; actionIndex < state.sidecarConfig[index]?.actionList.length ; actionIndex++){
						if(state.sidecarConfig[index].actionList[actionIndex].status)
							delete state.sidecarConfig[index].actionList[actionIndex].status
					}
				}
			}
		},
		removeSidecarActionList(state, action) {
			const uuid = action.payload.uuid;
			const actionUUID = action.payload.actionUUID;
			for (let index = 0; index < state.sidecarConfig.length; index++) {
				if (state.sidecarConfig[index].uuid === uuid) {
					for(let indexOfAction = 0; indexOfAction < state.sidecarConfig[index].actionList.length; indexOfAction++){
						if(state.sidecarConfig[index].actionList[indexOfAction].uuid === actionUUID){
							state.sidecarConfig[index].actionList = [
								...state.sidecarConfig[index].actionList.slice(0, indexOfAction),
								...state.sidecarConfig[index].actionList.slice(indexOfAction + 1),
							];
						}
					}
					break;
				}
			}
		},
		setTabSelected(state, action) {
			state.tabSelected = action.payload;
		},
		setShowSidecarSidebar(state, action) {
			state.showSidecarSidebar = action.payload;
		},
		setShowButtonProgramming(state, action) {
			state.showButtonProgramming = action.payload;
		},
		setShowChooseActions(state, action) {
			state.showChooseActions = action.payload;
		},
		setShowPauseDialogue(state, action) {
			state.showPauseDialogue = action.payload;
		},
		setShowDialDialogue(state, action) {
			state.showDialDialogue = action.payload;
		},
		setShowConferenceDialogue(state, action) {
			state.showConferenceDialogue = action.payload;
		},
		setShowMainStatusDialogue(state, action) {
			state.showMainStatusDialogue = action.payload;
		},
		setShowAdditionalStatusDialogue(state, action) {
			state.showAdditionalStatusDialogue = action.payload;
		},
		setShowSendMessageDialogue(state, action) {
			state.showSendMessageDialogue = action.payload;
		},
		setShowKeypressDialogue(state, action) {
			state.showKeypressDialogue = action.payload;
		},
		setShowTransferDialogue(state, action) {
			state.showTransferDialogue = action.payload;
		},
		sendMessage(state, action) {
			state.sendMessage = action.payload;
		},
		uploadToServer(state, action) {
			state.uploadToServer = action.payload;
		},
		updateStatus(state, action) {
			state.updateStatus = action.payload;
		},
	},
});

export const { setTabSelected } = sidecarSlice.actions;
export const { setShowSidecarSidebar } = sidecarSlice.actions;
export const { setShowButtonProgramming } = sidecarSlice.actions;
export const { setShowChooseActions } = sidecarSlice.actions;
export const { setShowPauseDialogue } = sidecarSlice.actions;
export const { setShowDialDialogue } = sidecarSlice.actions;
export const { setShowConferenceDialogue } = sidecarSlice.actions;
export const { setShowMainStatusDialogue } = sidecarSlice.actions;
export const { setShowAdditionalStatusDialogue } = sidecarSlice.actions;
export const { setShowSendMessageDialogue } = sidecarSlice.actions;
export const { setShowKeypressDialogue } = sidecarSlice.actions;
export const { setShowTransferDialogue } = sidecarSlice.actions;
export const { addSidecarAction } = sidecarSlice.actions;
export const { moveSidecarActionList } = sidecarSlice.actions;
export const { removeSidecarAction } = sidecarSlice.actions;
export const { removeSidecarActionList } = sidecarSlice.actions;
export const { setSidecarConfigNewTemp } = sidecarSlice.actions;
export const { setSidecarConfigNewTempUUID } = sidecarSlice.actions;
export const { setSidecarConfigNewTempName } = sidecarSlice.actions;
export const { setSidecarConfigNewTempExtension } = sidecarSlice.actions;
export const { removeSidecarConfigNewTempActionList } = sidecarSlice.actions;
export const { resetSidecarConfigNew } = sidecarSlice.actions;
export const { addSidecarConfigNewTempActionList } = sidecarSlice.actions;
export const { editSidecarAction } = sidecarSlice.actions;
export const { executePlaySidecarAction } = sidecarSlice.actions;
export const { executePauseSidecarAction } = sidecarSlice.actions;
export const { executeStopSidecarAction } = sidecarSlice.actions;
export const { executeNextSidecarAction } = sidecarSlice.actions;
export const { sendMessage } = sidecarSlice.actions;
export const { updateShowStatusMenu } = sidecarSlice.actions;
export const { setLastSync } = sidecarSlice.actions;
export const { setFullScreen } = sidecarSlice.actions;
export const { setSidecarView } = sidecarSlice.actions;

export default sidecarSlice.reducer;
