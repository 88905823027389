import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";

const Callback = React.lazy(() => import("pages/Callback"));

export const callbackRoutes: RouteObject[] = [
	{
		path: routePaths.CALLBACK.__PATH,
		element: (
			<React.Suspense fallback="Loading...">
				<Callback />
			</React.Suspense>
		),
	},
];
