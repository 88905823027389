import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";

const CallHistory = React.lazy(() => import("pages/CallHistory"));

export const callHistoryRoutes: RouteObject[] = [
	{
		path: routePaths.CALL_HISTORY.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback="Loading...">
					<CallHistory />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
