import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";

const Settings = React.lazy(() => import("./../../pages/Settings"));

export const settingsRoutes: RouteObject[] = [
	{
		path: routePaths.SETTINGS.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback="Loading...">
					<Settings />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
