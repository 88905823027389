import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import React from "react";

const Fax = React.lazy(() => import("pages/Fax"));

export const faxRoutes: RouteObject[] = [
	{
		path: routePaths.FAX.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback="Loading...">
					<Fax />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
