import React from "react";
import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";

const Voicemail = React.lazy(() => import("../../pages/Voicemail"));

export const voicemailRoutes: RouteObject[] = [
	{
		path: routePaths.VOICEMAIL.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback="Loading...">
					<Voicemail />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
