import styles from "./Login.module.scss";
import { useNavigate } from "react-router-dom";
import loginSideImage from "./../../assets/images/bg/login.png";
import Input from "./../../components/UI/Forms/Input";
import Button from "./../../components/UI/Forms/Button";
import Logo from "./../../components/UI/Logo";
import ErrorMessage from "components/UI/ErrorMessage";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/UI/Loader";
import sip from "../../lib/sip";
import { store } from "../../redux/store";
import { getGoBackUrl, getLoginUrl } from "config/env.config";
import { useTheme } from "hooks/useTheme";
import OpenApp from "components/shared/OpenApp";
import ChevronUpIcon from "components/UI/Icons/Lines/ChevronUp";
import ChevronDownIcon from "components/UI/Icons/Lines/ChevronDown";
import MailIcon from "components/UI/Icons/Lines/Mail";
import LockIcon from "components/UI/Icons/Lines/Lock";
import ExtensionIcon from "components/UI/Icons/Extension";
import BtnLarge from "components/UI/BtnLarge";
import Cookie from "js-cookie";

const Login = () => {
	const navigate = useNavigate();
	const theme = useTheme();

	const { authMessage, authLoading } = useSelector((state: any) => state.sip);

	interface FormData {
		extension?: string;
		server?: string;
		secret?: string;
		extensionErrorMsg?: string;
		serverErrorMsg?: string;
		secretErrorMsg?: string;
	}

	const [withExt, setWithExt] = useState(false);
	const [form, setForm] = useState<FormData>({ extension: "", secret: "", server: "" });

	const onContinueWithRingplan = () => {
		document.cookie =
			"id_token=eyJhbGciOiJSUzI1NiIsImtpZCI6ImVHeXkwR1Z0YXZHeFVnX3FMbUdqXzgyODNDWEoyWTdnLW1CdVFSZlNjV0EiLCJ0eXAiOiJKV1QifQ.eyJ2ZXIiOiIxLjAiLCJpc3MiOiJodHRwczovL3JpbmdwbGFuLmIyY2xvZ2luLmNvbS9kZDgzOTc4OS0xYzExLTQ4YWYtYTQxMy1lZTVhOGRjM2I5MTkvdjIuMC8iLCJzdWIiOiJmMWRmNDdmNy1kMmVhLTRiZjktOTg0My02ODk2ZGMwZTZhYjIiLCJhdWQiOiI3MzZjMzdkMy1jYTFjLTQ2NWItOGIzNi01ZWRkMDRkMTI5ZjMiLCJleHAiOjE3MTAxNjUwNjAsImlhdCI6MTcxMDEzNjI2MCwiYXV0aF90aW1lIjoxNzEwMTM2MjU5LCJnaXZlbl9uYW1lIjoiRGVlcGNoYW5kIiwiZmFtaWx5X25hbWUiOiJTaW5naCIsImVtYWlscyI6WyJkZWVwQHN0YXJ0eGxhYnMuaW4iXSwidGlkIjoiZGQ4Mzk3ODktMWMxMS00OGFmLWE0MTMtZWU1YThkYzNiOTE5IiwiYXRfaGFzaCI6InE4aGVSMkl4S2FXSU9JOHA4WWFQM1EiLCJuYmYiOjE3MTAxMzYyNjB9.kmmL1JsJpXNthC0obYDvnyolPPaWbldTc2SzGYmigjXWyRc8LvlnMuSKmjdHGrPy6oM9mKehYboJhdFFz1TDNz1FfhPVUSF2aW_GUO_K7RpXfXdiaUQcZnA3V2vGBcjeyvnA3U6XPcLMG5KM62BjphjJTAT-HwcfURbyilLjUAJHBGZOu5yXzsGfHoLaJfKygb3Bhl5FNhMFMsYMnA0Ux8G1t2nSk-5npmuZ5wlv0-otiaDY9W4V5XGkKzC8gfgqq7yMq8B1Voo2YChqjlhI6RDn-GHC-EKYLfUqB0lc2hoeuquXp6hThV_nQvnWtGgetQ1XM_JQ0h-tBKhtqswmBA; secure=true; samesite=None; path=/";
		document.cookie =
			"refresh_token=eyJraWQiOiJ5Z3VrV2JudEN1NHIyZzFKd2lZLWlqQk9SczhIaGVYOE13TExSTV8tRnBnIiwidmVyIjoiMS4wIiwiemlwIjoiRGVmbGF0ZSIsInNlciI6IjEuMCJ9.y_-KoGRWh2yw7zbVsC7LE-RTfiLLoEB6xR3KJsn6Z8tHQQQrzTaSdkn_Qou2XZWva8WS7CHvnh9QaF7EAmhvpHda8C5f22MvRAloc9WQoS4jZyGK01QScatShziMp4NiFF13xYxYo8pFXwaCsxpihlOGRsod3qGfwtSRHbGx6Vm_6ojSwGvY2jjRgKymUXjPY9hn-JEtpqed4CaDaxm0Yiuroo9Mx1axlJhmBtNR6rJvSAw5ZX4ma-28kKI-IVNQ6vDrF_A5fAEhCgiR5KyllVk35XfctRWFncWf5XDZOl3_0fo86ZIGgwIsII-CgYllPTFKnKTSDPN77pS_LNyOvQ.PI81GRlRfu2huTk-._v8_8OhOTIV8cTBU1Er21Xt86kYCWpzSfZoXeT4Lx77ouF3741ctIwGH2GKTjJbek4enxXNwAj8QDUy2iRzEsW4VUA3n5UkuwCDH73z4iM97Oa8rTC8gZhqLrLwYAbVNH-X5ronU3kiJaLDIqqB3vwnSC1W43JBrrVTQy85XJKUjjwU3eR7kTgazHMGuLKjETk_Ekr-V7KOpjzg9v9xZtSqSTucDq9SL-zwJ9iB_D16w5MDyJ1AvNI3HAsNoAzlAw-EcLNGJbUxOCoe8tyxXQSM1JWb0vH8gVEVe7pWrybWJp7Dy7yN57qFw2wkM4bIeY_uK0mBaS95IS6KQDrvtDdjCXBf0QFS4ZzqusvO0sTZ49hxGTYila0Exh_ybXSBGb6BNbN-6WGGtbNlORIrWWApw6lPXCRmXxk_Dk4ck8hcJ4FQW29sTtROr8tXab_jYoMIXS4FcHojOT2W8Jg_YlVCDIB1278t9YRtna9OmylKCBNKAJL440eV-4WlHP2AEK5rzMmq3SsZyOKnVmRuKpTb-5jvEgMt9rUiBJbzZVhf4YGhBtc0micGupDpMobxoKbaEDnFagE8GveARu-3-iQ3ANbie97Wm9n6gh6U-rVImfO_WaZTqswFcTS--HZqUI9jQ3PHowLbj4tOs9fIHzA._xPd8z5ji252z-ZEFrlYSw; secure=true; samesite=None; path=/";
		navigate("/callback");
		return;
		sip.logout(false);
		store.dispatch({ type: "sip/authMessage", payload: "" });
		// navigate("/dashboard"); // Remove the extra parentheses
		const loginUrl = getLoginUrl();
		const backUrl = getGoBackUrl();
		window.location.href = `${loginUrl}/login?back=${backUrl}`;
	};

	const loginWithExtension = () => {
		sip.logout(false);
		store.dispatch({ type: "sip/extAuth", payload: true });

		localStorage.setItem("extAuth", "true");

		form?.extension === ""
			? setForm((prevState) => {
					return { ...prevState, extensionErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, extensionErrorMsg: "" };
			  });
		form?.server === ""
			? setForm((prevState) => {
					return { ...prevState, serverErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, serverErrorMsg: "" };
			  });
		form?.secret === ""
			? setForm((prevState) => {
					return { ...prevState, secretErrorMsg: "This field is required" };
			  })
			: setForm((prevState) => {
					return { ...prevState, secretErrorMsg: "" };
			  });
		form.extension && form.server && form.secret && sip.CreateUserAgent(form.extension, form.secret, form.server);
		// sip.CreateUserAgent("", "", "")
	};

	useEffect(() => {
		if (authMessage === "continue") {
			navigate("/dashboard");
		}
	}, [authMessage]);

	const login = () => {
		return (
			<section className={`${styles.login} ${theme}`}>
				{/* {suggestPortraitOnMobileModalShow && <SuggestPortraitOnMobileModal />} */}
				<div className={styles.login_image}>
					<img src={loginSideImage} alt="" />
				</div>
				<div className={styles.login_textBox}>
					<div className={styles.login_text}>
						<OpenApp />
						<h1 className={styles.login_join}>Join the RingPlan Team</h1>
						<p className={styles.login_doMore}>Do more with Ringplan.</p>

						<div className={styles.continueRingplan}>
							<Button onClick={onContinueWithRingplan} icon={<Logo type="ri" />} border>
								<span>Continue with Ringplan</span>
							</Button>
						</div>

						<div className={styles.or}>or</div>

						{/* <button className={styles.login_withExtension}> */}
						<button className={styles.loginWithExtension_toggle} onClick={() => setWithExt(!withExt)}>
							Login with extension and secret
							{withExt ? (
								<div className={styles.loginWithExtension_chevronUp}>
									<ChevronUpIcon />
								</div>
							) : (
								<div className={styles.loginWithExtension_chevronDown}>
									<ChevronDownIcon />
								</div>
							)}
						</button>

						<div className={styles.loginWithExtension}>
							{withExt ? (
								<>
									<div className={styles.loginWithExtension_inputs}>
										<Input
											type="text"
											errorMsg={form?.extensionErrorMsg}
											value={form?.extension}
											onChange={(e) => setForm({ ...form, extension: e.target.value })}
											placeholder="Enter extension here..."
											required
											icon={<ExtensionIcon />}
										/>
										<Input
											type="text"
											errorMsg={form?.serverErrorMsg}
											value={form?.server}
											onChange={(e) => setForm({ ...form, server: e.target.value })}
											placeholder="zraytechnoloDoobh.ringplan.com"
											required
											icon={<MailIcon />}
										/>
										<Input
											type="password"
											errorMsg={form?.secretErrorMsg}
											value={form?.secret}
											onChange={(e) => setForm({ ...form, secret: e.target.value })}
											placeholder="Enter secret code here..."
											required
											icon={<LockIcon />}
										/>
										{authMessage && authMessage !== "continue" ? <ErrorMessage msg={authMessage} /> : ""}
									</div>

									<div className={styles.btn}>
										<BtnLarge
											btnType="primary"
											isDanger={false}
											isDisabled={false}
											isLoading={false}
											onClick={loginWithExtension}
											btnText="Sign In"
										/>
									</div>

									{/* <Button fill onClick={loginWithExtension}>
										Sign In
									</Button> */}
								</>
							) : null}
						</div>

						{/* <p className={`caption_1 ${styles.login_forgotPassword}`}>
							Forgot your password? <span>Click here</span>
						</p> */}
					</div>
				</div>
			</section>
		);
	};
	return authLoading ? <Loader /> : login();
};

export default Login;
