import { RouteObject } from "react-router-dom";
import routePaths from "./../../constants/routes";
import ProtectedRoutes from "utils/ProtectedRoutes";
import React from "react";

const Chat = React.lazy(() => import("pages/Chat"));

export const chatRoutes: RouteObject[] = [
	{
		path: routePaths.CHAT.__PATH,
		element: (
			<ProtectedRoutes>
				<React.Suspense fallback="Loading...">
					<Chat />
				</React.Suspense>
			</ProtectedRoutes>
		),
	},
];
