const XMainIcon = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="15"
		height="15"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
		className="lucide lucide-x"
		color="var(--icon-primary)">
		<path d="M18 6 6 18" />
		<path d="m6 6 12 12" />
	</svg>
);

export default XMainIcon;
